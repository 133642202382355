import React, { useState } from 'react';
import { Card } from './Profile_card'
interface ScreenProps{
    isFr: boolean;
    SwitchFrEn: ()=>void;
    isConnected: boolean;
    SwitchConnection: ()=>void;
    queryParams: URLSearchParams;
  }

interface Member {
    profilePic: string;
    name: string;
    role: string;
    poles: string[];
}

type Pole = {
    name:string;
    desc: string;
}

type ElementListProps = {
    elements: React.ReactNode[];
};

type PoleProps = {
    pole:string;
    change_selection: (pole:string)=>void;
}



const membres = [{profilePic:'pp_mathieu_cropped.png', name:'Mathieu', role:'Président', poles:['Bureau','Communication','E-sport']},
                 {profilePic:'pp_titouan_cropped.png', name:'Titouan', role:'Vice-président', poles:['Bureau','Web']},
                 {profilePic:'pp_axelg_cropped.png', name:'Axel G', role:'Trésorier', poles:['Bureau']},
                 {profilePic:'pp_etienne_cropped.png', name:'Etienne', role:'Vice-trésorier', poles:['Bureau']},
                 {profilePic:'pp_felicite_cropped.png', name:'Félicité', role:'Secrétaire générale', poles:['Bureau','Ekiden','Sport au qotidien']},
                 {profilePic:'pp_iba_cropped.png', name:'Iba', role:'Vice-président Ekiden', poles:['Bureau','Ekiden']},
                 {profilePic:'pp_albane_cropped.png', name:'Albane', role:'Listeuse', poles:['Communication','Campagne']},
                 {profilePic:'pp_axeld_cropped.png', name:'Axel D', role:'Listeux', poles:['Ekiden','Web']},
                 {profilePic:'pp_axelj_cropped.png', name:'Axel J', role:'Listeux', poles:['Ekiden','Sport au quotidien','WER']},
                 {profilePic:'pp_cyprien_cropped.png', name:'Cyprien', role:'Listeux', poles:['Ekiden','Sport au quotidien']},
                 {profilePic:'pp_eliot_cropped.png', name:'Eliot', role:'Listeux', poles:['WER','Ekiden']},
                 {profilePic:'pp_henri_cropped.png', name:'Henri', role:'Listeux', poles:['Ekiden','Sport au quotidien']},
                 {profilePic:'pp_julien_cropped.png', name:'Julien', role:'Listeux', poles:['Web','Event','WER']},
                 {profilePic:'pp_leopold_cropped.png', name:'Léopold', role:'Listeux', poles:['Event','Sport au quotidien']},
                 {profilePic:'pp_manel_cropped.png', name:'Manel', role:'Listeuse', poles:['Event','Sport au quotidien']},
                 {profilePic:'pp_matheo_cropped.png', name:'Mathéo', role:'Listeux', poles:['Event','Campagne']},
                 {profilePic:'pp_martin_db_cropped.png', name:'Martin DB', role:'Listeux', poles:['Event','Campagne']},
                 {profilePic:'pp_martin_p_cropped.png', name:'Martin P', role:'Listeux', poles:['Event','Sport au quotidien']},
                 {profilePic:'pp_paul_cropped.png', name:'Paul', role:'Listeux', poles:['Campagne','Event']},
                 {profilePic:'pp_quentin_cropped.png', name:'Quentin', role:'Listeux', poles:['Ekiden','Event']},
                 {profilePic:'pp_sarah_cropped.png', name:'Sarah', role:'Listeuse', poles:['Communication','Sport au quotidien']},
                 {profilePic:'pp_thomas_cropped.png', name:'Thomas', role:'Listeux', poles:['Campagne','Communication']},
];



  
const ElementList: React.FC<ElementListProps> = ({ elements }) => {
return (
    <div className='organigramme'>
    {elements.map((element, index) => (
        <div key={index}>{element}</div>
    ))}
    </div>
);
};

export const Members_to_display: React.FC<PoleProps> = ({pole, change_selection}) => {

    
    const members_of_pole = (pole==='BDS') ? membres : membres.filter((membre) => membre.poles.includes(pole));
    const members_of_pole_cards = members_of_pole.map(function ({profilePic,name,role,poles}) {
                                                            return <Card profilePic={"photos_profil/"+profilePic} name={name} role={role} poles={poles} change_selection={change_selection}/>;});
    return (<main>
            <ElementList elements={members_of_pole_cards}/>
    </main>)
}