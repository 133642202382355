import React, { ChangeEvent, useState } from 'react';
import { Card } from '../components/Profile_card'
import { Members_to_display } from '../components/Organigramme_liste';
import { Liste_clubs } from '../components/Liste_clubs';
interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
    isConnected: boolean;
    SwitchConnection: () => void;
    queryParams: URLSearchParams;
}

const poles_descriptions = [
    { name: 'BDS', desc: 'Le mandat actuel du Bureau des Sports' },
    { name: 'Bureau', desc: '' },
    { name: 'Ekiden', desc: "" },
    { name: "WER", desc: "Organise le Week-end Randonnée" },
    { name: "Sport au quotidien", desc: "" },
    { name: "Campagne", desc: "" },
    { name: "Communication", desc: "" },
    { name: "Event", desc: "" },
    { name: "E-sport", desc: "" },
    { name: "Web", desc: "" }
]



export const Organigramme: React.FC<ScreenProps> = ({ isFr, SwitchFrEn, isConnected, SwitchConnection, queryParams }) => {
    const [selection, setSelection] = useState('BDS');
    const [description, setDescription] = useState('');


    const select_display = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const description = poles_descriptions.find((obj) => obj.name === value)!;
        setSelection(value);
        setDescription(description.desc);
    }


    return (
        <main>
            <div className='pt-24'>
                <div className='flex flex-col items-center justify-center'>
                    <form action='choose_org_disp'>
                        <select name='org_disp' id='org_disp' onChange={select_display}>
                            <option value='BDS'>BDS</option>
                            <option value='Bureau'>Bureau</option>
                            <option value='Campagne'>Campagne</option>
                            <option value='Communication'>Communication</option>
                            <option value='Ekiden'>Ekiden</option>
                            <option value='Event'>Event</option>
                            <option value='Sport au quotidien'>Sport au quotidien</option>
                            <option value='Web'>Web</option>
                            <option value='WER'>WER</option>
                        </select>
                    </form>
                    <h4>{(description != "") && description}</h4>
                </div>
            </div>
            <div>
                <div className='organigramme'>
                    {Members_to_display({ 'pole': selection, 'change_selection': setSelection })}
                </div>
            </div>
        </main>
    )
}
