import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

type Event = {
  _id: string;
  nom: string;
  date_debut: Date;
  date_fin: string;
  heure_debut: string;
  heure_fin: string;
  lieu: string;
  details_short: string;
  details: string;
  prix_cotisant: number;
  prix_non_cotisant: number;
  event_de_billetterie: boolean;
  lien_cagnotte: string;
}

type CalendarEvent = {
  title: string;
  start: Date;
  end: Date;
  location: string;
}


const CalendarPage = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    // Fetch events from the backend
    axios.get('/api/events')
      .then(response => {
        // Map the events to the format react-big-calendar expects
        const events = response.data.map((event: Event) => ({
          title: event.nom,
          start: new Date(event.date_debut),
          end: new Date(event.date_fin),
          location: event.lieu
        }));
        setEvents(events);
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  return (
    <div className="pt-5 flex flex-col justify-center items-center bg-gray-100 border border-gray-300 rounded-lg shadow-lg overflow-hidden my-20 max-w-5xl mx-auto">
      <h1 className='font-semibold text-xl mx-3 my-10'>Retrouvez ici tous les événements du BDS</h1>
      <iframe src="https://calendar.google.com/calendar/embed?src=c_3a970ab9aaa064fec6e815bd5b128d90182e5dd6bf238217b73153549f9136c5%40group.calendar.google.com&ctz=Europe%2FParis" width="800" height="600" scrolling="no"></iframe>
    </div>
  );
};

export default CalendarPage;
